// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-tsx": () => import("./../../../src/pages/a.tsx" /* webpackChunkName: "component---src-pages-a-tsx" */),
  "component---src-pages-b-tsx": () => import("./../../../src/pages/b.tsx" /* webpackChunkName: "component---src-pages-b-tsx" */),
  "component---src-pages-c-tsx": () => import("./../../../src/pages/c.tsx" /* webpackChunkName: "component---src-pages-c-tsx" */),
  "component---src-pages-e-c-tsx": () => import("./../../../src/pages/e/c.tsx" /* webpackChunkName: "component---src-pages-e-c-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lead-chooice-services-tsx": () => import("./../../../src/pages/lead/chooice-services.tsx" /* webpackChunkName: "component---src-pages-lead-chooice-services-tsx" */),
  "component---src-pages-lead-data-tsx": () => import("./../../../src/pages/lead/data.tsx" /* webpackChunkName: "component---src-pages-lead-data-tsx" */),
  "component---src-pages-s-c-tsx": () => import("./../../../src/pages/s/c.tsx" /* webpackChunkName: "component---src-pages-s-c-tsx" */),
  "component---src-templates-article-category-tsx": () => import("./../../../src/templates/article-category.tsx" /* webpackChunkName: "component---src-templates-article-category-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-execution-tsx": () => import("./../../../src/templates/execution.tsx" /* webpackChunkName: "component---src-templates-execution-tsx" */),
  "component---src-templates-executions-category-tsx": () => import("./../../../src/templates/executions-category.tsx" /* webpackChunkName: "component---src-templates-executions-category-tsx" */),
  "component---src-templates-service-category-tsx": () => import("./../../../src/templates/service-category.tsx" /* webpackChunkName: "component---src-templates-service-category-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

